import React, { useState } from "react";
import ReactDOM from "react-dom";
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// Componenets
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import DateRangePicker from "../../UI/DateRangePicker/DateRangePicker";
import MultiInputField from "../../UI/MultiInputField/MultiInputField";
import { ReportsSuccessModal } from "../Reports/ReportsSuccessModal";
// Utils
import { APIConfig } from "../../services/apiConfiguration";
import axios from "axios";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
import FeatureUnavailable from "../../UI/FeatureUnavailable/FeatureUnavailable";

const initialValues = {
  provider_code: "",
  date_range: {
    start_date: "",
    end_date: "",
  },
  report_format: "",
  email: "",
};

const validationSchema = Yup.object({
  provider_code: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }),
  date_range: Yup.object({
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("End date is required")
      .min(Yup.ref("start_date"), "End date must be after start date"),
  }).required("Date range is required"),
  report_format: Yup.object()
    .shape({
      value: Yup.string().required("Please select a report format"),
      label: Yup.string().required("Please select a report format"),
    })
    .required("Report format is required"),
  email: Yup.array()
    .of(
      Yup.string().email("Invalid email format").required("Email is required")
    )
    .max(3, "You can only enter up to 3 email addresses")
    .required("At least one email address is required")
    .test(
      "at-least-one-email-address",
      "At least one Email Address is required",
      (value) => value && value.length > 0
    ),
});

const reportFormatOptions = [
  { value: "xlsx", label: "XLSX" },
  { value: "csv", label: "CSV" },
  { value: "json", label: "JSON" },
];

const providerOptions = [
  { value: "all", label: "ALL" },
  { value: "yesb", label: "Yes Bank" },
];

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CommissionSettlements = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  const submitHandler = (values, action) => {
    const adjustStartDate = new Date(values.date_range.start_date);
    adjustStartDate.setDate(adjustStartDate.getDate() + 1);

    const adjustEndDate = new Date(values.date_range.end_date);
    adjustEndDate.setDate(adjustEndDate.getDate() + 1);

    const payload = {
      provider_code: values?.provider_code?.value,
      start_time: adjustStartDate.toISOString().slice(0, 10),
      end_time: adjustEndDate.toISOString().slice(0, 10),
      email_params: {
        to_emails: values.email,
      },
    };
    action.setSubmitting(true);
    APIConfig.API_Client.post(
      apiEndpointList.COMMISSION_SETTLEMENT.baseUrl +
        `/decentro/operations/${values.report_format.value}/commission_settlement_report`,
      payload
    )
      .then((response) => {
        setSuccessMessage(
          "The file will be downloadable using the link below in a while."
        );
        setDownloadUrl(response.data.url);
        setShowSuccessModal(true);
        action.setSubmitting(false);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
        action.setSubmitting(false);
      })
      .finally(() => {
        action.resetForm();
      });
  };

  if (process.env.REACT_APP_ENV_NAME === "staging") {
    return <FeatureUnavailable />;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="provider_code"
                name="provider_code"
                options={providerOptions}
                placeholder="Select Provider"
                label="Provider"
                value={formik.values.provider_code}
                onChange={(selectedOption) => {
                  formik.setFieldValue("provider_code", selectedOption);
                }}
                required={true}
              />

              <DateRangePicker
                startDate={formik.values.date_range.start_date}
                endDate={formik.values.date_range.end_date}
                onStartDateChange={(date) =>
                  formik.setFieldValue("date_range.start_date", date)
                }
                onEndDateChange={(date) =>
                  formik.setFieldValue("date_range.end_date", date)
                }
                required={true}
                label="Date Range"
                maxDate={new Date()}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="report_format"
                name="report_format"
                onChange={(selectedOption) =>
                  formik.setFieldValue("report_format", selectedOption)
                }
                value={formik.values.report_format}
                options={reportFormatOptions}
                label="Report Format"
                required={true}
                placeholder="Select Format"
              />

              <MultiInputField
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                touched={formik.touched.email}
                error={formik.errors.email}
                placeholder="Enter email address(es)"
                label="Email Address(es)"
                required={true}
                extraHint="Press Enter after entering each email address. Max 3 email addresses allowed."
              />
            </div>

            <div className="settlement-details-submit ui-button-container">
              <button
                className={`settlement-details-submit ${
                  formik.isValid &&
                  formik.dirty &&
                  !formik.isSubmitting &&
                  Object.keys(formik.errors).length === 0
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={
                  // !formik.isValid || formik.isSubmitting || !formik.dirty
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !formik.dirty ||
                  Object.keys(formik.errors).length > 0
                }
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showSuccessModal && (
        <ReportsSuccessModal
          setShowModal={setShowSuccessModal}
          message={successMessage}
          url={downloadUrl}
        />
      )}
    </>
  );
};

export default CommissionSettlements;
