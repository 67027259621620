import React, { createContext, useState } from "react";

const DMOPaymentChannelContext = createContext();

export const DMOPaymentChannelProvider = ({ children }) => {
  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState(null);

  return (
    <DMOPaymentChannelContext.Provider
      value={{
        selectedCompanyDetails,
        setSelectedCompanyDetails,
      }}
    >
      {children}
    </DMOPaymentChannelContext.Provider>
  );
};

export default DMOPaymentChannelContext;
