import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";

//component-import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
// import MasterSearchResponseDetails from "./MasterSearchResponseDetails";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import { TotpModal } from "./TotpModal/TotpModal";
import ResponseDetails from "./ResponseDetails/ResponseDetails";

//utility-import
import { scrollIntoView } from "../../utilities/scrollIntoView";
import { randomUUID } from "../../services/randomUUID";
import { REGEXP } from "../../utilities/validators/inputValidators";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SingleCbaIdDecryption = () => {
  const [responseDetails, setResponseDetails] = useState([]);
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const responseRef = useRef(null);

  const singleCBADecryptionSubmit = () => {
    setShowResponseDetails(false);
    setResponseDetails(null);
    setErrorResponse(null);
    // parameter_value to remove any spaces
    const payload = {
      cba_ids: formik.values.parameter_value.replace(/\s+/g, ''),
    };

    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.CBA_ID_DECRYPTION.baseUrl + paAPIEndpoints.CBA_ID_DECRYPTION.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setResponseDetails(response.data);
        setShowResponseDetails(true);
        if (response.data.url) {
          const link = document.createElement("a");
          link.href = response.data.url;
          link.setAttribute("download", "file.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        formik.resetForm();
      })
      .catch((error) => {
        setResponseDetails(null);
        setShowResponseDetails(false);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
        setIsLoading(false);
        if (responseRef.current) {
          scrollIntoView(responseRef.current, { behavior: "smooth" });
        }
      });
  };

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
      paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const {
    CBARegex,
  } = REGEXP;

  const formik = useFormik({
    initialValues: {
      parameter_value: "",
    },
    validationSchema: Yup.object({
      parameter_value: Yup.string()
        .matches(CBARegex, 'Invalid format. Must be comma-separated numbers.')
        .required('Parameter value is required'),
    }),
    onSubmit: async (values, actions) => {
      setIsLoading(true);
      submitHandler(values, actions);
    },
  });

  const handleCloseDetails = (index) => {
    setResponseDetails((prevDetails) =>
      prevDetails.filter((_, i) => i !== index)
    );
  };

  const closeResponse = () => {
    setShowResponseDetails(false);
  };


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">Enter the decryption Parameter</h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="parameter_value"
                name="parameter_value"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.parameter_value}
                touched={formik.touched.parameter_value}
                error={formik.errors.parameter_value}
                placeholder="Enter parameter value"
                label="Parameter Value"
                required={true}
                disabled={false}
                isToolTip={"Multiple values can be added, separated by commas (e.g., 123, 234)."}
              />
            </div>
            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${formik.isValid && formik.dirty && !formik.isSubmitting && !isLoading
                  ? "active"
                  : "disabled"
                  }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
        {showResponseDetails && (
          <div ref={responseRef}>
            <ResponseDetails
              data={responseDetails}
              onClick={closeResponse}
              errorResponse={errorResponse}
            />
          </div>
        )}
        {errorResponse && (
          <div className="error-response">
            <h3>Error:</h3>
            <pre>{JSON.stringify(errorResponse, null, 2)}</pre>
          </div>
        )}
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={singleCBADecryptionSubmit}
          formik={formik}
        />
      ) : null}
    </>
  );
};

export default SingleCbaIdDecryption;
