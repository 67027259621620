import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
// api config imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
// component imports
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// styles imports
import "./BalanceRow.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const BalanceRowStaging = ({
  bankName,
  accountType,
  accountNumber,
  provider,
}) => {
  const [displayBalance, setDisplayBalance] = useState("");
  const [balanceStatus, setBalanceStatus] = useState("loading");
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");

  const getAccountBalance = (account) => {
    const date = new Date();

    setBalanceStatus("loading");
    setLastUpdatedTime(date.toLocaleTimeString("en-GB"));

    APIConfig.API_Client.defaults.headers.get["provider_code"] = provider;

    APIConfig.API_Client.get(
      apiEndpointList.BANKING_ACCOUNT.baseUrl +
        apiEndpointList.BANKING_ACCOUNT.endpoint +
        `?account_number=${account}&business_account=true`,
      { cancelToken: source.token }
    )
      .then((response) => {
        setBalanceStatus("success");
        setLastUpdatedTime(date.toLocaleTimeString("en-GB"));
        setDisplayBalance(
          response?.data?.data?.account_details?.current_balance || "-"
        );
      })
      .catch((error) => {
        setBalanceStatus("failed");
        console.log("Error Balance", error);
        // * snackbar for error
        // ReactDOM.render(
        //   <SnackbarMessage
        //     msgtype="Error"
        //     msg={error?.response?.data?.message || "Something went wrong !"}
        //   />,
        //   document.getElementById("snackbar")
        // );
      });
  };

  useEffect(() => {
    if (!!accountNumber) {
      getAccountBalance(accountNumber);
    }
  }, []);

  return (
    <>
      <tr>
        <td>{bankName}</td>
        <td>
          {accountType}
          {/* <span className="last-updated-time">Last updated on : {lastUpdatedTime}</span> */}
        </td>
        <td>{accountNumber}</td>
        <td className="last-updated-time">{lastUpdatedTime}</td>
        {/* Account Balance */}
        <td className="balance">
          <span className="balance-value">
            {balanceStatus === "success" && displayBalance}
          </span>
          {balanceStatus === "failed" && "Retry Again"}
          {balanceStatus === "loading" && (
            <img
              className="table-loader"
              src="/images/loader.gif"
              alt="loader"
            />
          )}
          {balanceStatus === "loading" ? (
            ""
          ) : (
            <span
              onClick={() => getAccountBalance(accountNumber)}
              className="refresh-icon-wrapper"
            >
              <img src="/images/refresh-icon.svg" alt="refresh icon" />
            </span>
          )}
        </td>
      </tr>
    </>
  );
};

export default BalanceRowStaging;
