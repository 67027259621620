import React from "react";
import ReactDOM from "react-dom";

// Styles Import
import "./ResponseDetails.scss";

// Components Import
import CopyChip from "../../../UI/CopyChip/CopyChip";

// Utils Import
import getSelectedProperties from "../../../utilities/getSelectedProperties";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

const ResponseDetails = ({ data, onClick }) => {
    const desiredProperties = ["message", "url"];
    const selected = getSelectedProperties(data, desiredProperties) || "-";
    const copyableData = selected;

    const copyToClipboard = (value) => {
        if (!value) return;
        navigator.clipboard.writeText(value);
        ReactDOM.render(
            <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
            document.getElementById("snackbar")
        );
    };

    const copyData = (e, value) => {
        e.preventDefault();
        copyToClipboard(value);
    };

    return (
        <div className="response-details-container">
            <h4 className="response-details-heading">
                <img
                    src={"/images/onsuccess-tick.svg"}
                    className="success-status-icon"
                    alt="status-icon"
                />
                Response Details
                <div className="right-icons-container">
                    <CopyChip
                        label="Copy All"
                        color="primary"
                        variant="outlined"
                        textToCopy={copyableData !== null ? copyableData : "-"}
                        iconSrc="/images/copy-icon.svg"
                    />
                    <img
                        src="/images/close.svg"
                        className="close-response-details"
                        alt="close"
                        onClick={onClick}
                    />
                </div>
            </h4>
            <div className="response-details-divider"></div>
            <div className="response-details">
                <ul className="response-list">
                    <li className="response-list-item">
                        <span className="field">URL: </span>
                        <span
                            className="value"
                            style={{
                                maxWidth: "200px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                            title={data.url}
                        >
                            {data.url ? (data.url.length > 30 ? `${data.url.slice(0, 27)}...` : data.url) : "-"}
                        </span>
                        {data.url && (
                            <button
                                onClick={(e) => copyData(e, data.url)}
                                style={{
                                    marginLeft: "8px",
                                    borderRadius: "4px",
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                            >
                                <img
                                    src="/images/copy-icon.svg"
                                    alt="Copy"
                                    style={{ width: "20px", height: "20px" }}
                                />
                            </button>
                        )}
                    </li>
                    <li className="response-list-item">
                        <span className="field">Message: </span>
                        <span className="value">{data.message || "-"}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ResponseDetails;
