import ReactDOM from "react-dom";
import React, { useState } from "react";
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// Components
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import DateRangePicker from "../../UI/DateRangePicker/DateRangePicker";
import MultiInputField from "../../UI/MultiInputField/MultiInputField";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import { ReportsSuccessModal } from "../Reports/ReportsSuccessModal";
// Utilities
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { APIConfig } from "../../services/apiConfiguration";
import FeatureUnavailable from "../../UI/FeatureUnavailable/FeatureUnavailable";

const initialValues = {
  report_type: "",
  provider: "",
  report_format: "",
  date_range: {
    start_date: "",
    end_date: "",
  },
  email: [],
};

const validationSchema = Yup.object({
  report_type: Yup.object()
    .shape({
      value: Yup.string().required("Please select a report type"),
      label: Yup.string().required("Please select a report type"),
    })
    .required("Report type is required"),
  provider: Yup.object()
    .shape({
      value: Yup.string().required("Please select a report type"),
      label: Yup.string().required("Please select a report type"),
    })
    .required("Report type is required"),
  report_format: Yup.object()
    .shape({
      value: Yup.string().required("Please select a report format"),
      label: Yup.string().required("Please select a report format"),
    })
    .required("Report format is required"),
  date_range: Yup.object({
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("End date is required")
      .min(Yup.ref("start_date"), "End date must be after start date"),
  }).required("Date range is required"),
  email: Yup.array()
    .of(
      Yup.string().email("Invalid email format").required("Email is required")
    )
    .max(3, "You can only enter up to 3 email addresses")
    .required("At least one email address is required")
    .test(
      "at-least-one-email-address",
      "At least one Email Address is required",
      (value) => value && value.length > 0
    ),
});

const reportTypeOptions = [
  { value: "transactions", label: "UPI" },
  { value: "transactions/e-collect", label: "E-collect" },
  { value: "settlement", label: "Settlements" },
  { value: "merchant-onboarding", label: "Merchant Onboarding" },
  { value: "refund-transactions", label: "Refunds" },
];

const providerOptions = [{ value: "yesb", label: "YES Bank" }];

const reportFormatOptions = [
  { value: "xlsx", label: "XLSX" },
  { value: "csv", label: "CSV" },
  { value: "json", label: "JSON" },
];

const AuditReports = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const endpoint =
        paAPIEndpoints.GET_AUDIT_REPORT.baseUrl +
        paAPIEndpoints.GET_AUDIT_REPORT.endpoint +
        `/${values?.report_format?.value}/reports/audit/${values?.report_type?.value}`;

      const adjustStartDate = new Date(values.date_range.start_date);
      adjustStartDate.setDate(adjustStartDate.getDate() + 1);

      const adjustEndDate = new Date(values.date_range.end_date);
      adjustEndDate.setDate(adjustEndDate.getDate() + 1);

      const payload = {
        start_date: adjustStartDate.toISOString().slice(0, 10),
        end_date: adjustEndDate.toISOString().slice(0, 10),
        provider_code: values?.provider?.value,
        email_params: {
          to_emails: values.email,
        },
      };
      action.setSubmitting(true);
      APIConfig.API_Client.post(endpoint, payload)
        .then((response) => {
          setSuccessMessage(response.data.message);
          setDownloadUrl(response.data.url);
          setShowSuccessModal(true);
          action.setSubmitting(false);
          action.resetForm();
        })
        .catch((error) => {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error?.response?.data?.message || "Something went wrong!"}
            />,
            document.getElementById("snackbar")
          );
          action.setSubmitting(false);
        });
    },
  });

  if (process.env.REACT_APP_ENV_NAME === "staging") {
    return <FeatureUnavailable />;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-divider"></div>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="report_type"
                name="report_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("report_type", selectedOption)
                }
                value={formik.values.report_type}
                options={reportTypeOptions}
                label="Report Type"
                required={true}
                placeholder="Select Report Type"
              />
              <TextFieldSelect
                id="provider"
                name="provider"
                options={providerOptions}
                placeholder="Select Provider"
                label="Provider"
                value={formik.values.provider}
                onChange={(option) => {
                  formik.setFieldValue("provider", option);
                }}
                required
              />
            </div>

            <div
              className="ui-form-inputs-section"
              style={{ position: "relative" }}
            >
              <DateRangePicker
                startDate={formik.values.date_range.start_date}
                endDate={formik.values.date_range.end_date}
                onStartDateChange={(date) => {
                  date?.preventDefault?.();
                  formik.setFieldValue("date_range.start_date", date);
                }}
                onEndDateChange={(date) => {
                  date?.preventDefault?.();
                  formik.setFieldValue("date_range.end_date", date);
                }}
                required={true}
                label="Date Range"
                maxDate={new Date()}
              />
              <TextFieldSelect
                id="report_format"
                name="report_format"
                onChange={(selectedOption) =>
                  formik.setFieldValue("report_format", selectedOption)
                }
                value={formik.values.report_format}
                options={reportFormatOptions}
                label="Report Format"
                required={true}
                placeholder="Select Format"
              />
            </div>
            <div className="ui-form-inputs-section">
              <MultiInputField
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                touched={formik.touched.email}
                error={formik.errors.email}
                placeholder="Enter email address(es)"
                label="Email Address(es)"
                required={true}
                extraHint="Press Enter after entering each email address. Max 3 email addresses allowed."
              />
            </div>

            <div className="settlement-details-submit ui-button-container">
              <button
                className={`settlement-details-submit ${
                  formik.isValid &&
                  formik.dirty &&
                  !formik.isSubmitting &&
                  Object.keys(formik.errors).length === 0
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !formik.dirty ||
                  Object.keys(formik.errors).length > 0
                }
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>

      {showSuccessModal && (
        <ReportsSuccessModal
          setShowModal={setShowSuccessModal}
          message={successMessage}
          url={downloadUrl}
        />
      )}
    </>
  );
};

export default AuditReports;
