export const trimPayload = (payload) => {
    // Base case: if payload is not an object or array, return it as-is
    if (typeof payload !== "object" || payload === null) {
        return typeof payload === "string" ? payload.trim() : payload;
    }

    // If payload is an array, recursively trim its elements
    if (Array.isArray(payload)) {
        return payload.map((item) => trimPayload(item));
    }

    // If payload is an object, recursively trim its values
    return Object.fromEntries(
        Object.entries(payload).map(([key, value]) => [
            key,
            trimPayload(value), // Recursively trim nested values
        ])
    );
};