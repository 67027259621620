import React from "react";
import ReactDOM from "react-dom";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

const withApiResponse = (WrappedComponent) => {
  return function WithApiResponseComponent({
    response,
    status,
    copyableKeys = [],
    visibleKeys = [],
    ...props
  }) {
    if (!response) return null;

    console.log("🚀 🚀 🚀 ~ withApiResponse ~ response:", response);

    const copyToClipboard = (text) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // You could add a toast notification here
          ReactDOM.render(
            <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
            document.getElementById("snackbar")
          );
        })
        .catch((err) => {
          console.error("Failed to copy:", err);
        });
    };

    const copyAllDetails = () => {
      const formattedText = Object.entries(response)
        .map(([key, value]) => {
          const formattedKey = formatLabel(key);
          const formattedValue =
            typeof value === "object" ? JSON.stringify(value) : value;
          return `${formattedKey}: ${formattedValue}`;
        })
        .join("\n");
      copyToClipboard(formattedText);
    };

    const getStatusConfig = () => {
      // Handle HTTP status codes
      if (status >= 500) {
        return {
          color: "#ED232A",
          title: "Server Error",
          icon: "/images/onfailure-error.svg",
        };
      }
      if (status >= 400) {
        return {
          color: "#ED232A",
          title: "Client Error",
          icon: "/images/onfailure-error.svg",
        };
      }
      if (status === 200 || status === 201 || status === 202) {
        // Check API specific status
        if (response.api_status === "SUCCESS") {
          return {
            color: "#34C47C",
            title: "Success",
            icon: "/images/success-tick.svg",
          };
        }
        if (response.api_status === "FAILURE") {
          return {
            color: "#ED232A",
            title: "Failed",
            icon: "/images/onfailure-error.svg",
          };
        }
        if (response.api_status === "PENDING") {
          return { color: "#FFB500", title: "Pending", icon: "⏳" };
        }
      }
      return { color: "#757575", title: "Unknown Status", icon: "❔" };
    };

    const statusConfig = getStatusConfig();

    const styles = {
      container: {
        padding: "20px",
        border: `1.5px solid ${statusConfig.color}`,
        borderRadius: "4px",
        marginTop: "20px",
        // maxWidth: '800px',
      },
      header: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        marginBottom: "16px",
        padding: "8px",
        // backgroundColor: `${statusConfig.color}15`,
        borderRadius: "4px",
      },
      title: {
        color: statusConfig.color,
        fontSize: "1rem",
        fontWeight: 600,
      },
      row: {
        display: "grid",
        gridTemplateColumns: "200px 1fr",
        padding: "8px 0",
        borderBottom: "1px solid #eee",
      },
      label: {
        fontWeight: 600,
        color: "#666",
      },
      value: {
        color: "#333",
        wordBreak: "break-all",
      },
      statusBadge: {
        backgroundColor: statusConfig.color,
        color: "white",
        padding: "4px 8px",
        borderRadius: "4px",
        fontSize: "12px",
      },
      copyButton: {
        fontSize: "12px",
        fontWeight: "500",
        cursor: "pointer",
        padding: "4px 8px",
        backgroundColor: "transparent",
        border: "1px solid #0092ff",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        marginLeft: "8px",
        transition: "background-color 0.2s",
        ":hover": {
          backgroundColor: "#e0e0e0",
        },
      },
      copyAllButton: {
        fontSize: "12px",
        fontWeight: "500",
        cursor: "pointer",
        padding: "8px 16px",
        backgroundColor: "transparent",
        border: "1px solid #0092ff",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        transition: "background-color 0.2s",
        ":hover": {
          backgroundColor: "#e0e0e0",
        },
        marginLeft: "auto",
      },
      copyButtonImg: {
        width: ".8rem",
        height: ".8rem",
      },
      valueContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flex: 1,
      },
    };

    const formatLabel = (key) => {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    return (
      <div style={styles.container}>
        <div style={styles.header}>
          {statusConfig?.icon ? (
            <img
              className="success-status-icon"
              src={statusConfig.icon}
              alt="success"
            />
          ) : null}
          <span style={styles.title}>{statusConfig.title}</span>
          <span style={styles.statusBadge}>
            {status} - {response.api_status || "N/A"}
          </span>

          <button
            onClick={copyAllDetails}
            style={styles.copyAllButton}
            title="Copy all details"
          >
            <img
              src="/images/copy-icon.svg"
              style={styles.copyButtonImg}
              alt="copy icon"
            />
            Copy All
          </button>
        </div>

        {Object.entries(response)
          .filter(
            ([key]) => visibleKeys.length === 0 || visibleKeys.includes(key)
          )
          .map(([key, value]) => (
            <div key={key} style={styles.row}>
              <span style={styles.label}>{formatLabel(key)}:</span>
              <div style={styles.valueContainer}>
                <span style={styles.value}>
                  {typeof value === "object" ? JSON.stringify(value) : value}
                </span>
                {/* Only show copy button for specified keys */}
                {copyableKeys.includes(key) && (
                  <button
                    onClick={() => copyToClipboard(value)}
                    style={styles.copyButton}
                    title="Copy value"
                  >
                    <img
                      src="/images/copy-icon.svg"
                      style={styles.copyButtonImg}
                      alt="copy icon"
                    />
                    Copy
                  </button>
                )}
              </div>
            </div>
          ))}

        <WrappedComponent {...props} />
      </div>
    );
  };
};

const SettlementAccountResponse = ({ response, status, copyableKeys }) => {
  return null;
};

export default withApiResponse(SettlementAccountResponse);
