import React, { useState } from "react";

// ui components
import DMO from "./DMO";
import PaymentChannelMapping from "./PaymentChannelMapping";
import TabComponentWrapper from "../../UI/TabComponentWrapper/TabComponentWrapper";

const DMOAndPaymentChannel = () => {
  return (
    <>
      <TabComponentWrapper
        heading="DMO & Payment Channel"
        tabs={[
          {
            label: "DMO",
            value: "dmo-standalone",
            component: <DMO />,
          },
          {
            label: "Payment Channel",
            value: "payment-channel-standalone",
            component: <PaymentChannelMapping />,
          },
        ]}
      />
    </>
  );
};

export default DMOAndPaymentChannel;
