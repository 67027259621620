import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Axios
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// utils
import { randomUUID } from "../../services/randomUUID";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import FormHeading from "../../UI/FormHeading/FormHeading";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import ResponsePaymentChannel from "./ResponsePaymentChannel/ResponsePaymentChannel";
import NavigationContainer from "./NavigationContainer";
import DMOPaymentChannelContext from "../../contexts/DMOPaymentChannelContext";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const DMO = ({ toPaymentChannelMapping }) => {
  const { setSelectedCompanyDetails } = useContext(DMOPaymentChannelContext);

  const [companyOptions, setCompanyOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);

  const [companyURNOptions, setCompanyURNOptions] = useState([""]);
  const [isURNLoading, setIsURNLoading] = useState(false);

  const [dmoAPIResponse, setDmoAPIResponse] = useState(null);

  const initialValues = {
    company_name: "",
    company_consumer_urn: "",
    reference_id: randomUUID(),
    provider_code: { label: "YES Bank", bank_code: "yesb" },
  };

  const validationSchema = Yup.object({
    company_name: Yup.object().shape({
      value: Yup.number().required("Please Select an Option"),
      label: Yup.string().required("Please Select an Option"),
    }),
    company_consumer_urn: Yup.object().shape({
      value: Yup.string().required("Please Select an Option"),
      label: Yup.string().required("Please Select an Option"),
    }),
    reference_id: Yup.string().required("Reference ID is required"),
  });

  // Fetching Companies to show in dropdown
  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.company_id,
          label: item.common_name,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  // Fetching Companies to show in dropdown
  const getCompanyBasedConsumerURN = () => {
    setIsURNLoading(true);
    // initial Reset
    formik.setFieldValue("company_consumer_urn", "");
    setCompanyURNOptions([]);

    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_BASED_CONSUMERS_URN.baseUrl +
        apiEndpointList.COMPANY_BASED_CONSUMERS_URN.endpoint,
      {
        target_company_id: formik?.values?.company_name?.value,
      },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          ...item,
          value: item.consumer_urn,
          label: item.consumer_urn,
        }));

        setCompanyURNOptions(options);
        setIsURNLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyURNOptions([]);
        setIsURNLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      setDmoAPIResponse(null);

      let payload = {
        reference_id: values.reference_id,
        provider_code: values?.provider_code?.bank_code,
        consumer_urn: values?.company_consumer_urn?.value,
      };

      formik.setSubmitting(true);

      setSelectedCompanyDetails({
        company_id: +values?.company_name?.value,
        consumer_urn: values?.company_consumer_urn?.value,
      });

      APIConfig.API_Client.post(
        paAPIEndpoints.MERCHANT_ONBOARDING_DMO.baseUrl +
          paAPIEndpoints.MERCHANT_ONBOARDING_DMO.endpoint,
        payload
        // { cancelToken: source.token }
      )
        .then((response) => {
          setDmoAPIResponse(response);
          //   setResponseDetails(response.data);
          //   setShowResponseDetails(true);
          ReactDOM.render(
            <SnackbarMessage msgtype="success" msg={response?.data?.message} />,
            document.getElementById("snackbar")
          );

          formik.resetForm();
        })
        .catch((error) => {
          setDmoAPIResponse(error);

          if (error.response) {
            console.log("Error Response Data:", error.response.data);

            ReactDOM.render(
              <SnackbarMessage
                msgtype="Error"
                msg={error?.response?.data?.message || "Something went wrong!"}
              />,
              document.getElementById("snackbar")
            );
          } else if (error.request) {
            // The request was made, but no response was received
            console.log("Error Request:", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error Message:", error.message);
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    getCompanyOptions();
  }, []);

  useEffect(() => {
    if (formik?.values?.company_name?.value) {
      getCompanyBasedConsumerURN();
      formik.setFieldValue("reference_id", randomUUID());
    }
  }, [formik?.values?.company_name?.value]);

  const navigateHandler = () => {
    toPaymentChannelMapping();
    setDmoAPIResponse(null);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormHeading headingText="Digital Merchant Onboarding" />
        <div className="ui-form-details">
          <div className="ui-form-content">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_name"
                name="company_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_name", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("company_name", true)}
                value={formik.values.company_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company"
                required={true}
                isLoading={isLoading}
                placeholder="Select company name"
                isformatOptionLabel={true}
                showOnlyLabelWhenSelected={true}
              />

              <TextFieldSelect
                id="company_consumer_urn"
                name="company_consumer_urn"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_consumer_urn", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("company_consumer_urn", true)
                }
                value={formik.values.company_consumer_urn}
                options={companyURNOptions}
                noOptionsMessage={() =>
                  `No such consumer URN exists for ${formik?.values?.company_name?.label}`
                }
                label="Consumer URN"
                required={true}
                isLoading={isURNLoading}
                placeholder="Select consumer URN"
                isformatOptionLabel={false}
                isDisabled={!formik?.values?.company_name?.value ? true : false}
              />
            </div>

            {/* 2 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="provider_code"
                name="provider_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.provider_code.label}
                touched={formik.touched.provider_code}
                error={formik.errors.provider_code}
                placeholder="Select provider"
                label="Provider"
                required={true}
                disabled={true}
              />

              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : "disabled"
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* <NavigationContainer
        submitHandler={navigateHandler}
        btnText="Skip DMO"
        messageText="To payment channel"
      /> */}

      {/* Navigate to Payment Channel container */}
      {/* {dmoAPIResponse?.status >= 200 && dmoAPIResponse?.status < 300 ? (
        <NavigationContainer
          submitHandler={navigateHandler}
          btnText="To Payment channel"
          messageText="DMO is successfully done"
        />
      ) : null} */}

      {/* Response Block */}
      <ResponsePaymentChannel
        response={
          dmoAPIResponse?.response?.data ||
          dmoAPIResponse?.response?.data?.data ||
          dmoAPIResponse?.data ||
          dmoAPIResponse?.data?.data
        }
        status={dmoAPIResponse?.status}
        copyableKeys={["decentro_txn_id", "message"]} // Only these fields will have copy buttons
      />
    </>
  );
};

export default DMO;
