import React from "react";
import Select from "react-select";

//component-import
import ToolTip from "../ToolTip/ToolTip";

//styles-import
import "./TextField.scss";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #cbcbcb",
    borderRadius: "10px",
    padding: "5px",
  }),

  placeholder: (base) => ({
    ...base,
    fontSize: "14px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    wordWrap: "break-word",
  }),

  option: (provided) => ({
    ...provided,
    fontSize: "14px",
    overflowX: "hidden",
    wordWrap: "break-word",
  }),
};

const formatOptionLabel = (
  { label, value },
  { context },
  showOnlyLabelWhenSelected
) => {
  return context === "menu" || !showOnlyLabelWhenSelected ? (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span
        style={{ wordWrap: "break-word", marginRight: "10px", width: "60%" }}
      >
        {label}
      </span>
      <span style={{ wordBreak: "break-all", textAlign: "end" }}>{value}</span>
    </div>
  ) : (
    <span>{label}</span>
  );
};

const TextFieldSelect = ({
  id,
  name,
  onChange,
  onBlur,
  isLoading,
  value,
  options,
  placeholder,
  label,
  noOptionsMessage,
  required,
  isToolTip,
  isformatOptionLabel,
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
  showOnlyLabelWhenSelected = false,
}) => (
  <div className={`ui-form-input-section ui-form-content-input`}>
    <Select
      id={id}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      value={value}
      options={options}
      styles={customStyles}
      noOptionsMessage={noOptionsMessage}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      maxMenuHeight={140}
      formatOptionLabel={
        isformatOptionLabel
          ? (data, context) =>
              formatOptionLabel(data, context, showOnlyLabelWhenSelected)
          : null
      }
    />
    <label className="ui-label" htmlFor={id}>
      {label}{" "}
      <ToolTip text={isToolTip} requiredField={required} isToolTip={isToolTip}>
        {isToolTip && (
          <div className="ui-info-icon">
            <img src="/images/info-icon.svg" alt="" />
          </div>
        )}
      </ToolTip>
    </label>
  </div>
);

export default TextFieldSelect;
